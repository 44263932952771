import React, {useState, useEffect} from 'react';
import './Orders.css'
import{useNavigate, useLocation, useParams } from "react-router-dom";
import Header from '../component/Header';

const OrdersDelivr = () => {
    const [product, setProduct] = useState([]);
    const [total, setTotal] = useState("")
    const [gstPrice, setGstPrice] = useState("")
    const [deliverPrice, setDeliverPrice] = useState("")
    const [orderTotal, setOrderTotal] = useState("")
    let history=useNavigate();
    let { orderId } = useParams();
    const location = useLocation();
    const API_URL = "http://3.19.227.171:8080";
    const isEnablePayBtn = false
    
    useEffect(() => {
        fetchMenuList()
      }, []);

      const fetchMenuList = async () => {
        const response = await fetch(`${API_URL}/api/orders/${global.orderId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        const res = await response.json();
        console.log(res)
        if(res.status == true){
        setProduct(res.data.orders.menu)
        setTotal(res.data.orders.total)
        setGstPrice(res.data.orders.gstPrice)
        setDeliverPrice(res.data.orders.deliverPrice)
        setOrderTotal(res.data.orders.orderTotal)
        }else{
            console.log(res.message)
        }
      };

      const divStyle={
        overflowY: 'scroll',
        border:'1px solid red',
        width:'500px',
        float: 'left',
        height:'500px',
        position:'relative'
      };

      const backBtn = () =>{
        history('/address');
      }
      const continueBtn = () => {
        history('/payment', {
            state: {
                total: total,
            }
            });
      }

    return(<div>
      <Header />
        {/* <img src={logo} style={{width: 100, height: 27, marginTop: 30, marginLeft: 55}} alt="logo" /> */}
        <div className="container">
        
     <p className="title">Your order</p>
     <p className="subTitle">Order will be delivered to you</p>
     <div className="scrollContainer">
    {
        product.map((item, index) => {
            return (
     <div className="itemContainer">
     <div className="topLine"/>
     <div className="line1">
     <p className="dishName">{item.dishName}</p>
     <p className="dishQuantity">{(Number(item.quantity )+ 1)}</p>
     <p className="dishPrice">₹ {item.price}</p>
     </div>
      </div>)})

    }
      <div className="topLine"/>
      <div className="price">
          <div className="priceLine">
              <p style={{width: 100, textAlign: "right", fontSize: 12, margin: 0}}>total</p>
              <p style={{width: 80, textAlign: "right", fontSize: 12,margin: 0}}>₹ {orderTotal}</p>
          </div>
          <div className="priceLine">
              <p style={{width: 100, textAlign: "right", fontSize: 12,margin: 0}}>GST at 20%</p>
              <p style={{width: 80, textAlign: "right", fontSize: 12,margin: 0}}>₹ {gstPrice}</p>
          </div>
          <div className="priceLine">
              <p style={{width: 100, textAlign: "right", fontSize: 12,margin: 0}}>Delivery</p>
              <p style={{width: 80, textAlign: "right", fontSize: 12,margin: 0}}>₹ {deliverPrice}</p>
          </div>
          <div className="priceLine">
              <p style={{width: 100, textAlign: "right", fontSize: 12, fontWeight: 'bold',margin: 0}}>Order total</p>
              <p style={{width: 80, textAlign: "right", fontSize: 12, fontWeight: 'bold',margin: 0}}>₹ {total}</p>
          </div>
      </div>
     </div>

       <div className="bottomBtn1">
     <button className="backBtn" type="button" onClick={()=>{backBtn()}}>Back</button>
     <button className="continueBtn" type="button" onClick={()=>{continueBtn()}}>Pay ₹ {total}</button>
     </div> 

     </div>
    </div>)

}

export default OrdersDelivr;