import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import fastpay from "../assets/images/fastpay.png";
import bottom_icon from "../assets/images/bottom_icon.png";
import "./Home.css";
const Home = () => {
  let history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // global.orderId = localStorage.getItem("orderId");
    // console.log(global.orderId);
    // if (global.orderId != null) {
    //   history("/viewReceipt/" + global.orderId);
    // }
  }, []);

  return (
    <div className="welcome_page">
      <div className="welcome_page_img">
        <img src={fastpay} style={{ width: 80, height: 22 }} alt="logo" />
      </div>
      <div className="welcome_page_heading">Sell home cooked</div>
      <div className="welcome_page_heading">food the easy way</div>
      <div className="welcome_page_text1">No credit card needed</div>
      <div className="btn_align">
        <button
          className="welcome_page_btn"
          type="button"
          style={{ marginTop: 40 }}
          onClick={(e) => {
            e.preventDefault();
            window.location.href =
              "https://api.whatsapp.com/send/?phone=917871100055&text&type=phone_number&app_absent=0";
          }}
        >
          Start selling now
        </button>
      </div>
      <div className="bottom_box">
        <img src={bottom_icon} style={{ width: 48, height: 48 }} alt="logo" />
        <div style={{ marginLeft: 32 }}>
          <div className="bottom_bold_text">What is FastPay?</div>
          <div className="bottom_text">
            We are an Android payments app for cloud kitchens.
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="bottom_text">
              <b>GST No.</b> 34AAMCM5316N1Z3 <b>CIN</b> U72900PY2019PTC008396
            </div>
            <div className="bottom_text">
              © FastPay (Owned by Monetyze Technologies Pvt Ltd.)
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
