import React, { useState, useEffect } from "react";
import "./Header.css";
import fastpay from "../assets/images/fastpay.png";
import contacts from "../assets/images/contacts.png";
const Header = () => {
  return (
    <div className="pContainerHeader">
      <div className="headerContain">
        {/* <p className="headerName">{global.businessName}</p> */}
        <img
          src={fastpay}
          style={{ width: 80, height: 22, marginRight: "auto" }}
          alt="logo"
        />
        <img
          src={contacts}
          style={{ width: 18, height: 18, marginLeft: "auto" }}
          alt="logo"
        />
      </div>
    </div>
  );
};
export default Header;
