import React, { useState, useEffect } from "react";
import "./Address.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "../component/Header";
import LoadingScreen from "react-loading-screen";
import { Buffer } from "buffer";
import fastpay from "../assets/images/fastpay.png";
import close from "../assets/images/close.png";
import pending from "../assets/images/pending.png";
import styled from "styled-components";

const Address = () => {
  var sha256 = require("js-sha256");
  const [address1, updateAddress1] = useState("");
  const [address2, updateAddress2] = useState("");
  const [city, updateCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [pincode, updatePincode] = useState("");
  let history = useNavigate();
  const location = useLocation();
  const API_URL = "https://prod.fastpaybharat.com";

  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const Box = styled.div`
    width: 100%;
    height: 150px;
    background-color: #ffe7df;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;

    @media (min-width: 450px) {
      width: 300px;
    }
  `;

  const backClick = () => {
    history(-1);
  };

  const isEnable = () => {
    return address1 != "" && address2 != "" && city != "" && pincode != "";
  };

  const handleClick = () => {
    setLoading(true);
    global.address1 = address1;
    global.address2 = address2;
    global.city = city;
    global.pincode = pincode;
    createNewCustomer();
  };

  const paymentHandler = async (e) => {
    const amount = parseInt(global.total) * 100;
    const currency = "INR";
    const receiptId = global.orderId;
    const response = await fetch(`${API_URL}/order`, {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    console.log(order);

    var options = {
      key: "rzp_live_oOrqE6YqoB35lL", // Enter the Key ID generated from the Dashboard
      amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency,
      name: "", //your business name
      description: "",
      // image: "https://example.com/your_logo",
      order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const body = {
          ...response,
        };

        const validateRes = await fetch(`${API_URL}/order/validate`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const jsonRes = await validateRes.json();
        console.log("ponmani Test");
        console.log(jsonRes);
        if (jsonRes.msg == "success") {
          updatePaymentStatusToFastpayServer(jsonRes);
        }
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: "", //your customer's name
        email: "",
        contact: "", //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#FE5B00",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  const updatePaymentStatusToFastpayServer = async (details) => {
    setLoading(true);
    const data = {
      user_phone: global.phoneNumber,
      order_id: global.orderId,
      payment_method_id: details.paymentId,
      status: "succeeded",
      amount: global.total,
      payment_method_type: "Razorpay",
      card_brand: null,
      card_country: null,
      card_exp_month: null,
      card_exp_year: null,
      card_funding: null,
      card_last4: null,
      livemode: false,
    };
    const response = await fetch(`${API_URL}/api/payments/add_payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const res = await response.json();
    console.log(res);
    // console.log(res);
    setLoading(false);
    if (res.status == true) {
      history("/viewReceipt/" + global.orderId);
    } else {
      console.log("error");
    }
  };

  const cratePayment = async () => {
    console.log("cratePayment");
    const response = await fetch(`${API_URL}/api/orders/pay`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        total: global.total,
        mid: global.tID,
        oid: global.orderId,
      }),
    });

    const res = await response.json();
    console.log(res);
    if (res.success == true) {
      setLoading(false);
      // console.log("hello")
      window.open(res.data.instrumentResponse.redirectInfo.url, "_self");
    } else {
      setLoading(false);
      console.log("error");
    }
  };

  const createNewCustomer = async () => {
    const address = {
      order_id: global.orderId,
      user_street: global.address1,
      user_city: global.address2,
      user_state: global.city,
      user_pincode: global.pincode,
    };
    const response = await fetch(
      `${API_URL}/api/address/updateOrdersUserAddress`,
      {
        method: "PUT",
        body: JSON.stringify(address),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const res = await response.json();
    console.log(res);
    setLoading(false);
    if (res.status == true) {
      // history("/pay");
      paymentHandler();
    } else {
      console.log(res.message);
    }
  };

  const topView = () => {
    return (
      <Container>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={fastpay} style={{ width: 80, height: 22 }} alt="logo" />
            <img
              src={close}
              style={{ width: 10, height: 10 }}
              alt="logo"
              onClick={backClick}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 5,
            }}
          >
            <div style={{ marginRight: 16 }}>
              <p
                style={{
                  color: "black",
                  fontSize: 20,
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                Payment request 👋
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: 12,
                  fontWeight: 400,
                  marginTop: 0,
                }}
              >
                Order ID- FH{global.fpOrderId}
              </p>
            </div>
            <img src={pending} style={{ width: 96, height: 30 }} alt="image" />
          </div>
        </Box>
      </Container>
    );
  };

  return (
    <div>
      {topView()}
      <div className="pContainerAddress">
        <div className="containerAddress">
          <p className="addressTitle">Your address</p>
          <p className="addressSubTitle">
            Food will be delivered to this addres
          </p>

          <div className="address1">
            <p className="topText"> Address line 1 </p>
            <input
              className="wideInput"
              type="text"
              onChange={(e) => {
                updateAddress1(e.target.value);
              }}
            />
          </div>
          <div className="address1">
            <p className="topText"> Address line (optional) </p>
            <input
              className="wideInput"
              type="text"
              onChange={(e) => {
                updateAddress2(e.target.value);
              }}
            />
          </div>
          <div className="address1">
            <p className="topText"> City / town </p>
            <input
              className="wideInput"
              type="text"
              onChange={(e) => {
                updateCity(e.target.value);
              }}
            />
          </div>
          <div className="address1">
            <p className="topText"> Pin code </p>
            <input
              className="wideInput"
              type="text"
              onChange={(event) => {
                const inputValue = event.target.value;
                // Remove any non-numeric characters
                const numericValue = inputValue.replace(/[^0-9]/g, "");
                // Update the input value
                event.target.value = numericValue;
                updatePincode(event.target.value);
              }}
            />
          </div>
          <div className="addressBottomBtn">
            <button
              className={
                isEnable() ? "addresscontinueBtn" : "continueDisableBtn"
              }
              type="button"
              disabled={!isEnable()}
              onClick={() => {
                handleClick();
              }}
            >
              Continue to payment
            </button>
          </div>
          <LoadingScreen
            loading={loading}
            bgColor="#F25F2A"
            spinnerColor="#9ee5f8"
            textColor="#FFFFFF"
            logoSrc=""
            text="Confirming secure payment..."
          />
        </div>
      </div>
    </div>
  );
};
export default Address;

// import React, {useState, useEffect} from 'react';
// import './Address.css'
// import{useNavigate, useLocation, useParams } from "react-router-dom";

// const Address = () => {

//     return(<div>
//          <div className="container">
//          <p className="title">Your order</p>
//      <p className="subTitle">Choose to pickup or get it delivered</p>
//          </div>
//     </div>)
// }
// export default Address;
