import React from "react";
import "./Money.css";
import { useNavigate } from "react-router-dom";
import fastpay from "../assets/images/fastpay-card.png";
import money from "../assets/images/play-btn.png";
import close from "../assets/images/close.png";

const Money = () => {
  const history = useNavigate();

  const handleOpenLink = () => {
    // Corrected URL
    window.location.href = `https://play.google.com/store/apps/details?id=com.monetyze.money`;
  };

  return (
    <div className="moneyContainer">
      <div className="innerContainer">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: 300,
            marginTop: 20,
          }}
        >
          <img
            src={close}
            style={{ width: 12, height: 12, margin: 0 }}
            alt="logo"
            onClick={() => {
              history(-1);
            }}
          />
        </div>
        <p
          style={{
            fontWeight: 400,
            fontSize: 20,
            color: "black",
            margin: 0,
            marginTop: 20,
          }}
        >
          Download Money app
        </p>
        <p
          style={{
            fontWeight: 700,
            fontSize: 14,
            color: "black",
            margin: 0,
            marginTop: 5,
          }}
        >
          👋 Claim your cash back
        </p>
        <img
          src={fastpay}
          style={{ width: 290, height: 194, marginTop: 10 }}
          alt="logo"
        />
        <div className="importantBox">
          <div className="importantText">Important</div>
          <p
            style={{
              margin: 0,
              marginTop: 8,
              marginLeft: 16,
              fontSize: 12,
              fontWeight: 400,
              color: "#B54708",
            }}
          >
            While installing the app, register with the same mobile number you
            used to place this order.
          </p>
        </div>
        <p
          style={{
            margin: 0,
            marginTop: 20,
            fontSize: 12,
            fontWeight: 400,
            color: "#9CA3AF",
          }}
        >
          👇 Click & download app - Claim your cash back
        </p>
        <button className="moneybutton" onClick={handleOpenLink}>
          <img src={money} style={{ width: 290, height: 80 }} alt="logo" />
        </button>
      </div>
    </div>
  );
};

export default Money;
