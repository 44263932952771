import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import "./Payment.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import useResponsiveFontSize from "../useResponsiveFontSize";
import Header from "../component/Header";
import LoadingScreen from "react-loading-screen";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const Payment = () => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  let history = useNavigate();
  const location = useLocation();
  const API_URL = "http://3.19.227.171:8080";

  const updateOrderTokichen = async () => {
    const response = await fetch(`${API_URL}/api/orders/${global.orderId}`, {
      method: "PUT",
      body: JSON.stringify({
        isPaid: true,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      history("/shareCode");
      setLoading(false);
    } else {
      console.log(res.message);
      setLoading(false);
    }
  };

  const createNewCustomer = async () => {
    const address = {
      line1: global.address1,
      line2: global.address2,
      city: global.city,
      pincode: global.pincode,
    };
    const orderedItem = {
      orderId: global.orderId,
      fpOrderId: global.fpOrderId,
      isPaid: true,
      isDelivery: false,
      isDelivered: false,
      deliveryType: global.deliveryType,
    };
    const response = await fetch(`${API_URL}/api/customers`, {
      method: "POST",
      body: JSON.stringify({
        address: address,
        orderedItem: orderedItem,
        kichenNumber: global.kichenNumber,
        phoneNumber: global.phoneNumber,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      updateOrderTokichen();
    } else {
      console.log(res.message);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    createNewCustomer();
    // event.preventDefault();

    // if (elements == null) {
    //   return;
    // }

    // const {error, paymentMethod} = await stripe.createPaymentMethod({
    //   type: 'card',
    //   card: elements.getElement(CardNumberElement),
    // });
  };

  const paymentBtn = () => {
    // history('/ordersDelivr');
    history("/address");
  };

  return (
    <div>
      <Header />
      <div className="pContainerPayment">
        <div className="container">
          <p className="title">Make payment</p>
          <p className="subTitle">Enter card details</p>

          <div className="box">
            <CardNumberElement options={options} />
          </div>

          <div className="cardDetails">
            <CardExpiryElement options={options} className="box1" />
            <CardCvcElement options={options} className="box2" />
          </div>
          <p className="secureText">
            Secured by Stripe - We don’t store your card data
          </p>
          {/* <p className="referalText">Got a referral code?</p> */}
          <div className="bottomBtn1">
            <button
              className="backBtn"
              type="button"
              onClick={() => {
                paymentBtn();
              }}
            >
              Back
            </button>
            {/* <button className="continueBtn" type="button" disabled={!stripe || !elements} onClick={()=>{handleSubmit()}}>Pay ₹ {location.state.total}</button> */}
            <button
              className="continueBtn"
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              Pay ₹ {location.state.total}
            </button>
          </div>
          <LoadingScreen
            loading={loading}
            bgColor="#F25F2A"
            spinnerColor="#9ee5f8"
            textColor="#FFFFFF"
            logoSrc=""
            text="Confirming secure payment..."
          />
        </div>
      </div>
    </div>
  );
};
export default Payment;
