import React, { useState, useEffect } from "react";
import "./StripePage.css";
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import fastpay from "../assets/images/fastpay.png";
import stripegst from "../assets/images/stripegst.png";
import secureIcon from "../assets/images/secure_icon.png";
import close from "../assets/images/close.png";

const StripePage = () => {
  let history = useNavigate();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const API_URL = "https://prod.fastpaybharat.com";
  //   useEffect(() => {
  //     fetch("http://localhost:8080/config").then(async (r) => {
  //       const { publishableKey } = await r.json();
  //       setStripePromise(loadStripe(publishableKey));
  //     });
  //   }, []);

  useEffect(() => {
    console.log(global.total);
    if (global.orderId == null) {
      history("/");
      return;
    }
    fetchMenuList();
  }, []);

  const backClick = () => {
    history(-1);
  };

  const fetchMenuList = async () => {
    const response = await fetch(
      `${API_URL}/api/orders/get_order_details_by_id?order_id=${global.orderId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      global.fpOrderId = res?.orderDetails?.order_id;
      global.orderDate = res?.orderDetails?.order_date;
      global.businessName = res?.orderDetails?.kitchen.name;
      global.phoneNumber = res?.orderDetails?.user?.user_phone;
      global.kichenNumber = res?.orderDetails?.kitchen?.phone;
      global.total = res?.orderDetails?.final_amount;
      if (res?.orderDetails?.status != "Pending") {
        history("/viewReceipt/" + global.orderId);
      } else {
        fetch(API_URL + "/create-payment-intent", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: global.total,
          }),
        }).then(async (result) => {
          var { clientSecret } = await result.json();
          setClientSecret(clientSecret);
        });
      }
    } else {
    }
  };

  const updatePaymentStatusToFastpayServer = async (paymentIntent) => {
    const cardDetails = paymentIntent?.paymentMethod?.Card;
    const cardBrand = cardDetails?.brand;
    const cardCountry = cardDetails?.country;
    const cardExpMonth = cardDetails?.expMonth;
    const cardExpYear = cardDetails?.expYear;
    const cardFunding = cardDetails?.funding;
    const cardLast4 = cardDetails?.last4;
    const details = {
      user_phone: global.phoneNumber,
      order_id: global.orderId,
      payment_method_id: paymentIntent?.id,
      status: paymentIntent?.status,
      amount: paymentIntent?.amount,
      payment_method_type:
        paymentIntent?.paymentMethod?.payment_method_types[0],
      card_brand: cardBrand,
      card_country: cardCountry,
      card_exp_month: cardExpMonth,
      card_exp_year: cardExpYear,
      card_funding: cardFunding,
      card_last4: cardLast4,
      livemode: false,
    };
    console.log(paymentIntent);
    console.log(details);
    const response = await fetch(`${API_URL}/api/payments/add_payment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });
    const res = await response.json();
    console.log(res);
    // console.log(res);
    if (res.status == true) {
      history("/viewReceipt/" + global.orderId);
    } else {
      console.log("error");
    }
  };

  const handleSubmit = async (e) => {
    // updatePaymentStatusToFastpayServer(false);
    // return;
    // history("/shareCode/" + global.orderId);
    // return;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: "",
        },
      },
    });

    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
      alert(result.error.message);
      // updatePaymentStatusToFastpayServer(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        // alert("payment success");
        updatePaymentStatusToFastpayServer(result.paymentIntent);
      }
    }

    setIsProcessing(false);
  };

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "black",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "black",
      },
    },
  };

  const topView = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "300px",
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={fastpay} style={{ width: 80, height: 22 }} alt="logo" />
            <img
              src={close}
              style={{ width: 10, height: 10 }}
              alt="logo"
              onClick={backClick}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="pContainerStripe">
      <div className="StripeContainer">
        {topView()}
        <div>
          <p className="StrOrderTotal">Order total</p>
          <p className="StrOrderPrice">₹ {global.total}</p>
        </div>
        <div style={{ width: 290, marginTop: 33 }}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <img
          src={stripegst}
          style={{
            width: 292,
            height: 32,
            marginTop: 131,
          }}
          alt="logo"
        />
        <div className="SecureBox">
          <img src={secureIcon} style={{ width: 12, height: 15 }} />
          <p className="SecureText">Secure payments by Stripe</p>
        </div>
        <div className="StrbottomBtn">
          <button
            className={stripe ? "StrcontinueBtn" : "StrcontinueDisableBtn"}
            type="button"
            disabled={!stripe}
            onClick={() => {
              handleSubmit();
            }}
          >
            Pay ₹ {global.total}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StripePage;
