import "./App.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Orders from "./pages/Orders";
import Address from "./pages/Address";
import OrdersDelivr from "./pages/OrdersDelivr";
import Payment from "./pages/Payment";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import ShareCode from "./pages/ShareCode";
import ViewReceipt from "./pages/ViewReceipt";
import Menu from "./pages/Menu";
import Home from "./pages/Home";
import KitchenMenu from "./pages/KitchenMenu";
import OrderCancel from "./pages/OrderCancel";
import StripePage from "./pages/StripePage";
import Completion from "./pages/Completion";
import Money from "./pages/Money";
// const stripePromise = loadStripe(
//   "pk_live_51I43BUCBnUYJ8BN8uUeI1ONUgEU45abG8P3wyjnlCUajF8DpRKQHRI5pxendiLWYoNI3ioJpvBwFAGqucp0JeTHf00ITDOUq4w"
// );
const stripePromise = loadStripe(
  "pk_test_51I43BUCBnUYJ8BN8as7O4jUQ3CnQ9jD3OhaXZ08dRwFC92pyVT8wxsznfHfb8sKbinvHnwEDw9xBFl1BFVGsr0uh001p1ZMj6f"
);
function App() {
  // const publishableKey = "pk_live_51I43BUCBnUYJ8BN8uUeI1ONUgEU45abG8P3wyjnlCUajF8DpRKQHRI5pxendiLWYoNI3ioJpvBwFAGqucp0JeTHf00ITDOUq4w"

  return (
    <Elements stripe={stripePromise}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="viewReceipt">
            <Route path=":orderId" element={<ViewReceipt />} />
          </Route>
          <Route path="/orders">
            <Route path=":orderId" element={<Orders />} />
          </Route>
          <Route path="/menu">
            <Route path=":menuId" element={<Menu />} />
          </Route>
          <Route path="/kitchen/menu">
            <Route path=":menuId" element={<KitchenMenu />} />
          </Route>
          <Route path="address" element={<Address />} />
          <Route path="ordersDelivr" element={<OrdersDelivr />} />
          <Route path="payment" element={<Payment />} />
          <Route path="shareCode">
            <Route path=":orderId" element={<ShareCode />} />
          </Route>
          <Route path="pay" element={<StripePage />} />
          <Route path="completion" element={<Completion />} />
          <Route path="ordercancel" element={<OrderCancel />} />
          {/* <Route path="viewReceipt" element={<ViewReceipt/>} /> */}
          <Route path="money" element={<Money />} />
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}

export default App;
