import React, { useState, useEffect } from "react";
import "./KitchenMenu.css";
import { useParams } from "react-router-dom";
import contacts from "../assets/images/contacts.png";
import poweredby from "../assets/images/fastpay_details.png";
import chillyone from "../assets/images/chillyone.png";
import chillytwo from "../assets/images/chillytwo.png";
import chillythree from "../assets/images/chillythree.png";

const KitchenMenu = () => {
  const API_URL = "https://prod.fastpaybharat.com";
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectedButton, setSelectedButton] = useState(0);
  let { menuId } = useParams();
  let categoryNames = ["Starter", "Main", "Side", "Dessert"];

  useEffect(() => {
    global.menuId = menuId;
    setLoading(true);
    fetchMenuList();
  }, []);

  const fetchMenuList = async () => {
    const response = await fetch(`${API_URL}/api/kitchens/fetch_kitchen`, {
      method: "POST",
      body: JSON.stringify({
        phone: menuId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const res = await response.json();
    if (res.status === true) {
      setLoading(false);
      global.businessName = res.data.kitchen.name;
      global.phoneNumber = menuId;
      setCategory(res.data.menu);
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  const CapsuleButton = ({ label, isSelected, onClick }) => {
    const buttonClassName = isSelected
      ? "kitchentapbutton selected"
      : "kitchentapbutton";

    return (
      <button className={buttonClassName} onClick={onClick}>
        {label}
      </button>
    );
  };

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
  };

  const tapButtonsRow = () => {
    return (
      <div className="tapButtonsRow">
        <CapsuleButton
          label="All"
          isSelected={selectedButton === 0}
          onClick={() => handleButtonClick(0)}
        />
        <CapsuleButton
          label="Starter"
          isSelected={selectedButton === 1}
          onClick={() => handleButtonClick(1)}
        />
        <CapsuleButton
          label="Main"
          isSelected={selectedButton === 2}
          onClick={() => handleButtonClick(2)}
        />
        <CapsuleButton
          label="Side"
          isSelected={selectedButton === 3}
          onClick={() => handleButtonClick(3)}
        />
        <CapsuleButton
          label="Dessert"
          isSelected={selectedButton === 4}
          onClick={() => handleButtonClick(4)}
        />
      </div>
    );
  };

  const headerView = () => {
    return (
      <div className="kitchenHeader">
        <p className="kichenNumber">{global.businessName}</p>
        <div className="kichenNumberView">
          <img
            src={contacts}
            style={{
              width: 18,
              height: 18,
              marginLeft: "auto",
              color: "#A3D0B2",
            }}
            alt="logo"
          />
          <div style={{ marginLeft: 10 }}>
            <p className="kitchenCallText">
              Call{" "}
              {global.phoneNumber != null ? (
                <b>
                  {global.phoneNumber.slice(0, 4) +
                    " " +
                    global.phoneNumber.slice(4, 7) +
                    " " +
                    global.phoneNumber.slice(7, 10)}
                </b>
              ) : null}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const getSpicyImage = (name) => {
    if (name === "Mild") {
      return <img className="categoryOptionImg1" src={chillyone} alt="Mild" />;
    } else if (name === "Medium") {
      return (
        <img className="categoryOptionImg2" src={chillytwo} alt="Medium" />
      );
    } else if (name === "Hot") {
      return <img className="categoryOptionImg3" src={chillythree} alt="Hot" />;
    }
  };

  const getGreenImage = (isVeg) => {
    if (isVeg === true) {
      return <div className="greenCircle"></div>;
    }
  };

  const renderItemsByCategory = () => {
    if (selectedButton === 0) {
      return categoryNames.map((categoryName) => {
        const filteredItems = category.filter(
          (item) => item.category === categoryName
        );
        if (filteredItems.length === 0) return null;

        return (
          <div key={categoryName} style={{ width: "100%" }}>
            <h2 className="kichenNumber">{categoryName}</h2>
            {filteredItems.map((item, index) => (
              <div key={index} className="kitchenItemRow">
                <div className="kitchenItemRowLine1">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className="kitchenDishName">{item.dish_name}</p>
                    {getSpicyImage(item.spicy)}
                    {getGreenImage(item.isVeg)}
                  </div>
                  <p className="kitchenDishPrice">₹ {item.price}</p>
                </div>
                <div className="kitchenItemRowLine1">
                  <img
                    className="kitchenDishImage"
                    src={item.food_url}
                    alt="image"
                  />
                  <p className="kitchenDescription">{item.description}</p>
                </div>
                <div className="kitchenmenuLine" />
              </div>
            ))}
          </div>
        );
      });
    } else {
      const filteredItems = category.filter(
        (item) => item.category === categoryNames[selectedButton - 1]
      );
      return filteredItems.map((item, index) => (
        <div key={index} className="kitchenItemRow">
          <div className="kitchenItemRowLine1">
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="kitchenDishName">{item.dish_name}</p>
              {getSpicyImage(item.spicy)}
              {getGreenImage(item.isVeg)}
            </div>
            <p className="kitchenDishPrice">₹ {item.price}</p>
          </div>
          <div className="kitchenItemRowLine1">
            <img className="kitchenDishImage" src={item.food_url} alt="image" />
            <p className="kitchenDescription">{item.description}</p>
          </div>
          <div className="kitchenmenuLine" />
        </div>
      ));
    }
  };

  return (
    <div className="kitchencontainer">
      {headerView()}
      {tapButtonsRow()}
      {renderItemsByCategory()}
      <div className="poweredbyBox">
        <img src={poweredby} className="poweredBy" alt="logo" />
      </div>
    </div>
  );
};

export default KitchenMenu;
