import React, { useState, useEffect } from "react";
import "./Orders.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Header from "../component/Header";
import LoadingScreen from "react-loading-screen";
import { Buffer } from "buffer";
import poweredby from "../assets/images/fastpay_details.png";
import fastpay from "../assets/images/fastpay.png";
import close from "../assets/images/close.png";
import pending from "../assets/images/pending.png";
import styled from "styled-components";

const Orders = () => {
  var sha256 = require("js-sha256");
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [total, setTotal] = useState("");
  const [gstPrice, setGstPrice] = useState("");
  const [gst, setGst] = useState("");
  const [deliverPrice, setDeliverPrice] = useState("");
  const [orderTotal, setOrderTotal] = useState("");
  const [delivrDate, setDelivrDate] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [kitchen, setKitchen] = useState(null);
  const [adminDetails, setAdminDetails] = useState(null);
  let history = useNavigate();
  let { orderId } = useParams();
  const location = useLocation();
  const API_URL = "https://prod.fastpaybharat.com";
  const isEnablePayBtn = false;

  useEffect(() => {
    global.orderId = orderId;
    localStorage.setItem("orderId", orderId);
    setLoading(true);
    fetchMenuList();
  }, []);

  const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const Box = styled.div`
    width: 100%;
    height: 150px;
    background-color: #ffe7df;
    padding: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;

    @media (min-width: 450px) {
      width: 300px;
    }
  `;

  const fetchMenuList = async () => {
    console.log("Start " + orderId);
    const response = await fetch(
      `${API_URL}/api/orders/get_order_details_by_id?order_id=${orderId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const res = await response.json();
    console.log(res);
    if (res.status == true) {
      global.fpOrderId = res?.orderDetails?.order_id;
      global.orderDate = res?.orderDetails?.order_date;
      global.businessName = res?.orderDetails?.kitchen.name;
      global.phoneNumber = res?.orderDetails?.user?.user_phone;
      global.kichenNumber = res?.orderDetails?.kitchen?.phone;
      global.total = res?.orderDetails?.final_amount;
      setProduct(res?.orderDetails?.order_details);
      setTotal(res?.orderDetails?.final_amount);
      // setGstPrice(res.data.orders.gstPrice);
      setDeliverPrice(res?.orderDetails?.delivery_cost);
      setOrderTotal(res?.orderDetails?.total_amount);
      // setGst(res.data.orders.gst);
      setDelivrDate(res?.orderDetails?.delivery_date);
      setAdminDetails(res?.orderDetails?.admin_details);
      setLoading(false);
      if (res?.orderDetails?.status != "Pending") {
        history("/viewReceipt/" + global.orderId);
      }
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  const divStyle = {
    overflowY: "scroll",
    border: "1px solid red",
    width: "500px",
    float: "left",
    height: "500px",
    position: "relative",
  };

  const pickupbtnClick = () => {
    global.deliveryType = "pickup";
    history("/address", {
      state: location.state,
    });
  };

  const deliverybtnClick = async () => {
    global.deliveryType = "delivery";
    history("/address", {
      state: location.state,
    });
  };

  const backClick = () => {
    history(-1);
  };

  const topView = () => {
    return (
      <Container>
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={fastpay} style={{ width: 80, height: 22 }} alt="logo" />
            {/* <img
              src={close}
              style={{ width: 10, height: 10 }}
              alt="logo"
              onClick={backClick}
            /> */}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 5,
            }}
          >
            <div style={{ marginRight: 16 }}>
              <p
                style={{
                  color: "black",
                  fontSize: 20,
                  fontWeight: 400,
                  marginBottom: 0,
                }}
              >
                Payment request 👋
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: 12,
                  fontWeight: 400,
                  marginTop: 0,
                }}
              >
                Order ID- FH{global.fpOrderId}
              </p>
            </div>
            <img src={pending} style={{ width: 96, height: 30 }} alt="image" />
          </div>
        </Box>
      </Container>
    );
  };

  return (
    <div>
      {topView()}
      <div className="pContainerOrder">
        <div className="containerOrder">
          <div className="scrollContainer">
            {product.map((item, index) => {
              return (
                <div className="fastpayitemContainer">
                  {index == 0 ? null : <div className="menuLine" />}
                  <div className="fastpayitemrow">
                    <p className="fastpaydishName">{item.dish_name}</p>
                    <p className="fastpaydishQuantity">
                      {Number(item.quantity) + 1}
                    </p>
                    <p className="fastpaydishPrice">₹ {item.price}</p>
                  </div>
                </div>
              );
            })}
            <div className="menuLine" />
            <div className="price">
              <div className="fastpaypriceLine">
                <p className="fastpayCalCulationText">Total</p>
                <p className="fastpayCalCulationText">₹ {orderTotal}</p>
              </div>
              <div className="fastpaypriceLine">
                <p className="fastpayCalCulationText">
                  GST at {adminDetails?.gst}%
                </p>
                <p className="fastpayCalCulationText">
                  ₹ {adminDetails?.gst_amount}
                </p>
              </div>
              <div className="fastpaypriceLine">
                <p className="fastpayCalCulationText">Delivery</p>
                <p className="fastpayCalCulationText">₹ {deliverPrice}</p>
              </div>
              <div className="fastpayTotalpriceLine">
                <p className="fastpayCalCulationBoldText">Order total</p>
                <p className="fastpayCalCulationBoldText">₹ {total}</p>
              </div>
            </div>
          </div>
          <div className="menuLine" />
          <p className="fastPayBusinessDateText">
            Delivery date - {delivrDate}
          </p>
          <div className="menuLine" />
          {/* <div className="bottomBtn"> */}
          {/* <button className="pickupBtn" type="button" onClick={()=>{pickupbtnClick()}}>Pickup</button> */}
          <p className="fastPayBusinessAddressText">
            {global.businessName} - will deliver your order.
          </p>
          <div className="menuLine" />
          <img src={poweredby} className="orderPoweredBy" alt="logo" />
          {/* <div className="fastpayBottomRow">
            <p className="fastPayGSTText">FastPay</p>
            <p className="fastPayGSTText">
              (Fully owned by Monetyze Technologies Pvt Ltd)
            </p>
            <div className="fastPayGSTROW">
              <p className="fastPayGSTText1">GST No.</p>
              <p className="fastPayGSTText">34AAMCM5316N1Z3</p>
            </div>
            <div className="fastPayGSTROW">
              <p className="fastPayGSTText1">CIN</p>
              <p className="fastPayGSTText">U72900PY2019PTC008396</p>
            </div>
          </div> */}
          {/* <div className="menuLine" /> */}
          <button
            className="deliveryBtn"
            type="button"
            onClick={() => {
              deliverybtnClick();
            }}
          >
            Enter delivery address
          </button>
          {/* </div> */}
          <LoadingScreen
            loading={loading}
            bgColor="#F25F2A"
            spinnerColor="#9ee5f8"
            textColor="#FFFFFF"
            logoSrc=""
            text="Fetching Order..."
          />
        </div>
      </div>
    </div>
  );
};

export default Orders;
