import React from "react";
import "./OrderCancel.css";
const OrderCancel = () => {
  return (
    <>
      <div className="ontainercancel">
        <div className="orderolder">Order older than 24hrs</div>
        <div className="ordercancelkitchen">Order cancelled by kitchen</div>
        <div className="ordernow">🍔 Still hungry? Place an order now.</div>
        <div className="backBtn">View Menu</div>
      </div>
    </>
  );
};

export default OrderCancel;
